'use client';

/**
 * Given two sets of parameters, ensure that all the subsetParams are contained in the params
 *
 * @param params
 * @param subsetParams
 * @returns
 */
export function hasSubsetParams(params: string | URLSearchParams, subsetParams: string | URLSearchParams): boolean {
    // Convert both to URLSearchParams objects
    const searchParamsA = new URLSearchParams(params);
    const searchParamsB = new URLSearchParams(subsetParams);

    // Make sure everything contained in B is also contained in A
    for (const key of searchParamsB.keys()) {
        // If the value of the key in searchParamsA is not equal to the value of the key in searchParamsB, return false
        if (searchParamsA.get(key) !== searchParamsB.get(key)) {
            return false;
        }
    }

    return true;
}

export function isSameParams(paramsA: string | URLSearchParams, paramsB: string | URLSearchParams): boolean {
    // Is A a subset of B?
    if (!hasSubsetParams(paramsA, paramsB)) {
        return false;
    }

    // Is B a subset of A?
    if (!hasSubsetParams(paramsB, paramsA)) {
        return false;
    }

    return true;
}
