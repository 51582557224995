'use client';
import { usePathname } from 'next/navigation';
import { normalizePath } from './normalizePath';

export function isSamePathname(pathA: string, pathB: string): boolean {
    const normalizedA = normalizePath(pathA);
    const normalizedB = normalizePath(pathB);
    return normalizedA === normalizedB;
}

export function useIsCurrentPathname(path: string): boolean {
    const currentPathname = usePathname();
    return isSamePathname(path, currentPathname);
}
